import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const BPPROOT = document.getElementById( 'root' );

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  BPPROOT
);

